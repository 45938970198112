import { DndContext } from "@dnd-kit/core";
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
} from "@dnd-kit/sortable";
import React, { useEffect, useState } from "react";
import { SketchPicker } from "react-color";
import Draggable from "./Draggable";
import Droppable from "./Droppable";
import SortableItem from "./SortableItem";
import { CiText, CiTextAlignJustify } from "react-icons/ci";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import BreadCrumb from "components/breadcrumb/BreadCrumb";
import { Button, Card, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FaEye, FaRegCheckSquare, FaVideo } from "react-icons/fa";
import { BsCardText, BsCash } from "react-icons/bs";
import { IoPhonePortraitSharp } from "react-icons/io5";
import {
  MdDateRange,
  MdOutlineColorLens,
  MdOutlineMailOutline,
  MdOutlineRadioButtonChecked,
} from "react-icons/md";
import { RiCalendarEventFill, RiNumber1 } from "react-icons/ri";
import { IoMdTime } from "react-icons/io";
import { CgSelectR } from "react-icons/cg";
import { BiSelectMultiple } from "react-icons/bi";
import { VscTypeHierarchy } from "react-icons/vsc";
import FieldPropertiesModal from "../modal/FieldPropertiesModal";
import { toast } from "react-toastify";
import { FieldSetsAPI } from "api";
import { customSettings } from "utils/customConfig";
import { useLocation } from "react-router-dom";

const schema = yup.object().shape({
  name: yup.string().required("Name is required"),
  sortOrder: yup
    .number()
    .typeError("Sort Order must be a number")
    .required("Sort Order is required"),
  fields: yup.array().of(
    yup.object().shape({
      key: yup.string().required(),
      type: yup.string().required(),
      properties: yup.object().shape({
        label: yup.string(),
        placeholder: yup.string(),
        required: yup.boolean(),
        readOnly: yup.boolean(),
      }),
    })
  ),
  status: yup.boolean(),
});

const initialItems = [
  {
    id: "text",
    content: "Text",
    type: "text",
    icon: <CiText size={20} />,
  },
  {
    id: "password",
    content: "Password",
    type: "password",
    icon: <FaEye size={20} />,
  },
  {
    id: "currency",
    content: "Currency",
    type: "currency",
    icon: <BsCash size={20} />,
  },
  {
    id: "phone",
    content: "Phone",
    type: "phone",
    icon: <IoPhonePortraitSharp size={20} />,
  },
  {
    id: "email",
    content: "Email",
    type: "email",
    icon: <MdOutlineMailOutline size={20} />,
  },
  {
    id: "number",
    content: "Number",
    type: "number",
    icon: <RiNumber1 size={20} />,
  },
  {
    id: "date",
    content: "Date",
    type: "date",
    icon: <MdDateRange size={20} />,
  },
  {
    id: "dateAndTime",
    content: "Date & Time",
    type: "datetime-local",
    icon: <RiCalendarEventFill size={20} />,
  },
  {
    id: "time",
    content: "Time",
    type: "time",
    icon: <IoMdTime size={20} />,
  },
  {
    id: "checkbox",
    content: "Checkbox",
    type: "checkbox",
    icon: <FaRegCheckSquare size={20} />,
  },
  {
    id: "textArea",
    content: "Rich Text",
    type: "textarea",
    icon: <BsCardText size={20} />,
  },
  {
    id: "radioBox",
    content: "Radio Box",
    type: "radio",
    icon: <MdOutlineRadioButtonChecked size={20} />,
  },
  {
    id: "select",
    content: "Select",
    type: "select",
    icon: <CgSelectR size={20} />,
  },
  {
    id: "multiSelect",
    content: "Multi Select",
    type: "multiSelect",
    icon: <BiSelectMultiple size={20} />,
  },
  // {
  //   id: "hierarchicalSelect",
  //   content: "Hierarchical Select",
  //   type: "hierarchicalSelect",
  //   icon: <VscTypeHierarchy size={20} />,
  // },
  {
    id: "colorPicker",
    content: "Color Picker",
    type: "color",
    icon: <MdOutlineColorLens size={20} />,
  },
  {
    id: "hidden",
    content: "Hidden",
    type: "hidden",
    icon: <FaEye size={20} />,
  },
  {
    id: "video",
    content: "Video",
    type: "video",
    icon: <FaVideo size={20} />,
  },
];

const FieldSetsForm = () => {
  const submittedValues = {};
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    reset,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      name: "",
      sortOrder: "",
      fields: [],
      status: false,
    },
    resolver: yupResolver(schema),
    shouldUnregister: true,
  });
  const navigate = useNavigate();
  const { state } = useLocation();
  const [formFields, setFormFields] = useState([]);
  const [formState, setFormState] = useState({});
  const [settingModal, setSettingModal] = useState(false);
  const [currentFieldKey, setCurrentFieldKey] = useState(null);
  const [fieldOfModal, setFieldOfModal] = useState(null);

  const convertData = (data) => ({
    name: data.name,
    sortOrder: data.sortOrder,
    status: data.status,
    fields: data.fields.map((field) => ({
      fieldSetId: 0, // Replace with actual fieldSetId if available
      name: field.properties.name,
      type: field.type,
      regEx: field.properties.regex,
      listValues: field.properties.listValues,
      description: "", // Placeholder
      label: field.properties.label,
      placeholder: field.properties.placeholder,
      dataSource: "", // Placeholder
      sortOrder: field.properties.sortOrder,
      defaultValue: field.properties.defaultValue,
      width: field?.properties?.styles?.width,
      length: 0, // Placeholder
      isRequired: field.properties.required,
      isReadOnly: field.properties.readOnly,
      parent: 0, // Placeholder
    })),
  });

  const submit = async (data) => {
    console.log(data);
    const convertedData = convertData(data);
    const myData = { ...convertedData, id: state ? state?.id : 0 };

    console.log("Form data:", myData);
    // console.log("converted data:", convertedData);

    try {
      if (state) {
        await FieldSetsAPI.post(myData);
        toast.success("FieldSets Updated Successfully", {
          position: customSettings.toastPosition,
          autoClose: customSettings.toastClose,
        });
      } else {
        await FieldSetsAPI.post(myData);
        toast.success("FieldSets Created Successfully", {
          position: customSettings.toastPosition,
          autoClose: customSettings.toastClose,
        });
      }

      // ------- Get all object keys form data and set empty values to reset ------------
      const keys = Object.keys(data);
      for (const key of keys) {
        submittedValues[key] = "";
      }
      reset({ ...submittedValues });
      navigate(-1);
    } catch (error) {
      toast.error("Something Went Wrong", {
        position: customSettings.toastPosition,
        autoClose: customSettings.toastClose,
      });
      console.log(error);
    }
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (!over) return;

    const activeIndex = formFields.findIndex(
      (field) => field.key === active.id
    );
    const overIndex = formFields.findIndex((field) => field.key === over.id);

    if (activeIndex !== -1 && overIndex !== -1) {
      const newFormFields = arrayMove(formFields, activeIndex, overIndex);
      setFormFields(newFormFields);
      setValue("fields", newFormFields); // Update fields in form state
      console.log(getValues()); // Debug current values
    } else {
      const draggedItem = initialItems.find((item) => item.id === active.id);
      if (draggedItem?.id) {
        const newField = {
          ...draggedItem,
          key: `${draggedItem?.id || ""}-${Date.now()}`,
          properties: {}, // Initialize properties
        };
        const newFormFields = [...formFields, newField];
        setFormFields(newFormFields);
        setValue("fields", newFormFields); // Update fields in form state
        setFormState((prev) => ({ ...prev, [newField.key]: "" }));
      }
    }
  };

  const handleInputChange = (key, value) => {
    setFormState((prev) => ({ ...prev, [key]: value }));
  };

  const handleDelete = (key) => {
    const newFormFields = formFields.filter((field) => field.key !== key);
    setFormFields(newFormFields);
    setValue("fields", newFormFields); // Update fields in form state
    setFormState((prev) => {
      const newState = { ...prev };
      delete newState[key];
      return newState;
    });
  };

  const handleApplyProperties = (key) => {
    // console.log(key);
    setCurrentFieldKey(key);
    setSettingModal(true);
  };

  const applyProperties = (key, data) => {
    // console.log(key, data)
    const newFormFields = formFields.map((field) =>
      field.key === key ? { ...field, properties: data } : field
    );
    setFormFields(newFormFields);
    setValue("fields", newFormFields); // Update fields in form state
  };

  const handleFieldSetting = (field) => {
    setFieldOfModal(field);
    // console.log(field);
  };

  function transformFields(fields) {
    return fields?.map((field, index) => {
      return {
        id: field.type,
        content: capitalize(field.type),
        type: field.type,
        icon: {
          key: null,
          ref: null,
          props: {
            size: 20,
          },
          _owner: null,
          _store: {},
        },
        key: `${field.type}-${index}-${Date.now()}`,
        properties: {
          name: field.name || "",
          label: field.label || "",
          placeholder: field.placeholder || "",
          readOnly: field.isReadOnly || false,
          required: field.isRequired || false,
          regex: field.regEx || "",
          defaultValue: field.defaultValue || "",
          sortOrder: field.sortOrder || 0,
          groupForRadioOrCheckBox: "",
          listValues: field.listValues || "",
          options: [
            {
              name: "",
              children: [],
            },
          ],
          styles: {
            width: field?.width || 210,
          },
        },
      };
    });
  }

  function capitalize(string) {
    return string?.charAt(0).toUpperCase() + string?.slice(1);
  }

  // console.log(formFields);

  // useEffect(() => {
  //   if (state) {
  //     setFormFields(transformFields(state?.fields));
  //     reset(state);
  //   }
  // }, [state, reset]);

  useEffect(() => {
    if (state) {
      setFormFields(transformFields(state?.fields));
      setValue("sortOrder", state.sortOrder || 0);
      setValue("name", state.name || "");
      setValue("fields", transformFields(state?.fields) || []);
      setValue("status", state.status || false);
      // const defaultValues = {
      //   name: state.name || "",
      //   sortOrder: state.sortOrder || "",
      //   fields: transformFields(state?.fields) || [],
      //   status: state.status || false,
      // };
      // reset(state); // Set initial values
    }
  }, [state, setValue]);

  return (
    <>
      <div className="py-2">
        <BreadCrumb />
      </div>
      <form onSubmit={handleSubmit(submit)}>
        <Card>
          <Card.Header className="border-bottom">
            <Row className="flex-between-center">
              <Col md>
                <h5 className="fs-9 mb-0 text-nowrap  py-xl-0">FieldSet</h5>
              </Col>
              <Col xs="auto">
                <Button
                  variant="link"
                  className="text-secondary fw-medium p-0 me-3"
                  type="button"
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </Button>
                <Button variant="primary" type="submit">
                  Ok
                </Button>
              </Col>
            </Row>
          </Card.Header>
          <div className="" style={{ padding: "5px 20px" }}>
            <Row className="gx-2 mb-1 d-flex align-items-center">
              <Col md="9">
                <Form.Group>
                  {/* <FloatingLabel
                    controlId="floatingInput"
                    label="Name"
                    className=""
                  > */}
                  <Form.Control
                    type="text"
                    placeholder="Name"
                    title="Name"
                    isInvalid={!!errors.name}
                    {...register("name")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name?.message}
                  </Form.Control.Feedback>
                  {/* </FloatingLabel> */}
                </Form.Group>
              </Col>
              <Col md="2">
                <Form.Group>
                  {/* <FloatingLabel
                    controlId="floatingInput"
                    label="Sort Order"
                    className=""
                  > */}
                  <Form.Control
                    type="number"
                    placeholder="Sort Order"
                    title="Sort Order"
                    isInvalid={!!errors.sortOrder}
                    {...register("sortOrder")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.sortOrder?.message}
                  </Form.Control.Feedback>
                  {/* </FloatingLabel> */}
                </Form.Group>
              </Col>
              <Col md="1">
                <Form.Check
                  type="checkbox"
                  id="defaultSwitch"
                  label="Active"
                  className="mt-2"
                  defaultChecked={!state}
                  isInvalid={!!errors.status}
                  {...register("status")}
                />
              </Col>
            </Row>

            <DndContext onDragEnd={handleDragEnd}>
              <div className="d-flex justify-content-between align-items-center gap-4 ">
                <div style={{ width: "80%" }}>
                  <Droppable id="droppable">
                    <SortableContext
                      items={formFields}
                      strategy={rectSortingStrategy}
                    >
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns:
                            "repeat(auto-fill, minmax(200px, 1fr))",
                          gridAutoRows: "min-content",
                          gap: "10px",
                          width: "100%",
                        }}
                        className="p-2"
                      >
                        {formFields.length === 0
                          ? ""
                          : formFields.map((field) => (
                              <SortableItem
                                key={field.key}
                                id={field.key}
                                onDelete={handleDelete}
                                handleSetting={() => handleFieldSetting(field)}
                                onApplyProperties={handleApplyProperties}
                                field={field}
                              >
                                <div
                                  style={{
                                    ...field.properties.styles,
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    {field.properties.label || (
                                      <div>&nbsp;</div>
                                    )}
                                    {field.type === "textarea" ? (
                                      <textarea
                                        value={formState[field.key] || ""}
                                        onChange={(e) =>
                                          handleInputChange(
                                            field.key,
                                            e.target.value
                                          )
                                        }
                                        placeholder={
                                          field.properties.placeholder ||
                                          `Enter ${field.type}`
                                        }
                                        // readOnly={field.properties.readOnly}
                                        // required={field.properties.required}
                                        style={{
                                          marginLeft: "10px",
                                          resize: "both",
                                        }}
                                        className="border border-gray-300 p-1 rounded-sm"
                                      />
                                    ) : field.type === "hidden" ? (
                                      <input
                                        value={formState[field.key] || ""}
                                        onChange={(e) =>
                                          handleInputChange(
                                            field.key,
                                            e.target.value
                                          )
                                        }
                                        placeholder={
                                          field.properties.placeholder ||
                                          `Enter ${field.type}`
                                        }
                                        // readOnly={field.properties.readOnly}
                                        // required={field.properties.required}
                                        style={{
                                          marginLeft: "10px",
                                          resize: "both",
                                        }}
                                        className="border border-gray-300 p-1 rounded-sm"
                                      />
                                    ) : field.type === "select" ? (
                                      <select
                                        value={formState[field.key] || ""}
                                        onChange={(e) =>
                                          handleInputChange(
                                            field.key,
                                            e.target.value
                                          )
                                        }
                                        // readOnly={field.properties.readOnly}
                                        // required={field.properties.required}
                                        style={{
                                          marginLeft: "10px",
                                        }}
                                        className="border border-gray-300 p-1 rounded-sm"
                                      >
                                        <option value="">
                                          Select an option
                                        </option>
                                        {(field.properties.listValues || "")
                                          .split("|")
                                          .map((option, index) => (
                                            <option
                                              key={index}
                                              value={option.trim()}
                                            >
                                              {option.trim()}
                                            </option>
                                          ))}
                                      </select>
                                    ) : field.type === "multiSelect" ? (
                                      <select
                                        value={formState[field.key] || ""}
                                        onChange={(e) =>
                                          handleInputChange(
                                            field.key,
                                            e.target.value
                                          )
                                        }
                                        // readOnly={field.properties.readOnly}
                                        // required={field.properties.required}
                                        style={{
                                          marginLeft: "10px",
                                        }}
                                        className="border border-gray-300 p-1 rounded-sm"
                                      >
                                        <option value="">
                                          Select an option
                                        </option>
                                        {(field.properties.listValues || "")
                                          .split("|")
                                          .map((option, index) => (
                                            <option
                                              key={index}
                                              value={option.trim()}
                                            >
                                              {option.trim()}
                                            </option>
                                          ))}
                                      </select>
                                    ) : field.type === "hierarchicalSelect" ? (
                                      <div
                                        style={{
                                          width: "100%",
                                          display: "flex",
                                          minWidth: "min-content",
                                        }}
                                      >
                                        <select
                                          value={formState[field.key] || ""}
                                          onChange={(e) =>
                                            handleInputChange(
                                              field.key,
                                              e.target.value
                                            )
                                          }
                                          // readOnly={field.properties.readOnly}
                                          // required={field.properties.required}
                                          style={{
                                            marginLeft: "10px",
                                          }}
                                          className="border border-gray-300 p-1 rounded-sm"
                                        >
                                          <option value="">
                                            Select an option
                                          </option>
                                          {(field.properties.options || []).map(
                                            (option, index) => (
                                              <option
                                                key={index}
                                                value={option.name}
                                              >
                                                {option.name}
                                              </option>
                                            )
                                          )}
                                        </select>
                                        {field.properties?.options?.some(
                                          (option) =>
                                            option.children?.length > 0
                                        ) && (
                                          <select
                                            value={formState[field.key] || ""}
                                            onChange={(e) =>
                                              handleInputChange(
                                                field.key,
                                                e.target.value
                                              )
                                            }
                                            // readOnly={field.properties.readOnly}
                                            // required={field.properties.required}
                                            style={{
                                              marginLeft: "10px",
                                            }}
                                            className="border border-gray-300 p-1 rounded-sm"
                                          >
                                            <option value="">
                                              Select an option
                                            </option>
                                          </select>
                                        )}
                                        {field.properties?.options?.some(
                                          (option) =>
                                            option.children.some((sub) =>
                                              sub.subOptions?.some(
                                                (sub2) =>
                                                  sub2?.children?.length > 0
                                              )
                                            )
                                        ) && (
                                          <select
                                            value={formState[field.key] || ""}
                                            onChange={(e) =>
                                              handleInputChange(
                                                field.key,
                                                e.target.value
                                              )
                                            }
                                            // readOnly={field.properties.readOnly}
                                            // required={field.properties.required}
                                            style={{
                                              marginLeft: "10px",
                                            }}
                                            className="border border-gray-300 p-1 rounded-sm"
                                          >
                                            <option value="">
                                              Select an option
                                            </option>
                                          </select>
                                        )}
                                      </div>
                                    ) : field.type === "color" ? (
                                      <SketchPicker
                                        color={formState[field.key] || "#000"}
                                        onChangeComplete={(color) =>
                                          handleInputChange(
                                            field.key,
                                            color.hex
                                          )
                                        }
                                      />
                                    ) : (
                                      <input
                                        type={field.type}
                                        value={formState[field.key] || ""}
                                        onChange={(e) =>
                                          handleInputChange(
                                            field.key,
                                            e.target.value
                                          )
                                        }
                                        placeholder={
                                          field.properties.placeholder ||
                                          `Enter ${field.type}`
                                        }
                                        // readOnly={field.properties.readOnly}
                                        // required={field.properties.required}
                                        style={{
                                          marginLeft: "10px",
                                        }}
                                        className="border border-gray-300 p-1 rounded-sm"
                                      />
                                    )}
                                  </div>
                                </div>
                              </SortableItem>
                            ))}
                      </div>
                    </SortableContext>
                  </Droppable>
                </div>
                <div style={{ width: "20%" }} className="flex flex-col ">
                  {initialItems.map((item) => (
                    <Draggable key={item.id} id={item.id}>
                      <div className="px-2 gap-2 d-flex align-items-center border rounded-sm">
                        {item.icon}
                        {item.content}
                      </div>
                    </Draggable>
                  ))}
                </div>
              </div>
            </DndContext>
          </div>
        </Card>
        <FieldPropertiesModal
          show={settingModal}
          setShow={setSettingModal}
          applyProperties={applyProperties}
          fieldKey={currentFieldKey}
          data={fieldOfModal}
        />
      </form>
    </>
  );
};

export default FieldSetsForm;
