import React, { useEffect, useState } from "react";
import {
  Form,
  Spinner,
  OverlayTrigger,
  Tooltip,
  Button,
  InputGroup,
} from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { useFetchCategoryVariants } from "hooks/useFetchCategoryVariants";
import { useFetchVariants } from "hooks/useFetchDetailVariants";
import useQuery from "hooks/useQuery";
import { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { MdOutlineLibraryAddCheck } from "react-icons/md";
import Swal from "sweetalert2";

const PricingAndStockInfo = () => {
  const {
    watch,
    setValue,
    register,
    formState: { errors },
  } = useFormContext();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const categoryId = watch("categoryId");
  // console.log(categoryId);
  const salePrice = watch("salesPrice");

  const { data: variantsData, isLoading: variantsLoading } = useFetchVariants();
  const { data: categoryVariantsData, isLoading: categoryVariantsLoading } =
    useFetchCategoryVariants();

  const filteredCategoryVariants = useMemo(() => {
    if (!categoryVariantsData || !categoryId) return [];
    return categoryVariantsData?.data?.data?.filter(
      (set) => set.categoryId === categoryId
    );
  }, [categoryVariantsData, categoryId]);

  const variantsIds = useMemo(() => {
    return filteredCategoryVariants?.flatMap((set) =>
      set.options.map((fs) => fs.variantId)
    );
  }, [filteredCategoryVariants]);

  const filteredVariantsOption = useMemo(() => {
    if (!variantsData || variantsIds.length === 0) return [];
    return variantsData?.data?.data?.filter((variant) =>
      variantsIds.includes(variant.id)
    );
  }, [variantsData, variantsIds]);

  const [inputs, setInputs] = useState([]);

  const permutations = useMemo(
    () => generatePermutations(filteredVariantsOption),
    [filteredVariantsOption]
  );

  const pricingAndStockFound = watch("pricingAndStock");

  useEffect(() => {
    const initialInputs = permutations.flatMap((perms) =>
      perms.permutations.flatMap((perm) =>
        perm.sizes.map((size) => ({
          color: perm.colors,
          material: perm.materials,
          size,
          price: "",
          specialPrice: "",
          stock: "",
          sku: "",
        }))
      )
    );
    setInputs(initialInputs);
    setValue("pricingAndStock", initialInputs);
  }, [permutations]);

  const handleInputChange = (index, field, value) => {
    const updatedInputs = inputs.map((item, i) =>
      i === index ? { ...item, [field]: value } : item
    );

    setInputs(updatedInputs);
    setValue("pricingAndStock", updatedInputs); // Ensure form context is updated
  };

  const handleApplyAll = (field, value) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want apply to all",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        const updatedInputs = inputs.map((row) => ({ ...row, [field]: value }));
        setInputs(updatedInputs);
        setValue("pricingAndStock", updatedInputs);
        // Swal.fire({
        //   title: "Deleted!",
        //   text: "Your file has been deleted.",
        //   icon: "success",
        // });
      }
    });
  };

  const handleApplyButtonClick = (index, field) => {
    const valueToApply = inputs[index][field];
    handleApplyAll(field, valueToApply);
  };

  useEffect(() => {
    if (pricingAndStockFound) {
      setInputs(pricingAndStockFound);
    }
  }, [pricingAndStockFound]);

  // if (variantsLoading || categoryVariantsLoading) {
  //   return <Spinner variant="primary" animation="border" />;
  // }

  // console.log(permutations);

  // console.log(inputs);

  return (
    <>
      {/* <table className="table table-bordered table-striped">
        {permutations.map((perms, index) => (
          <React.Fragment key={index}>

            <thead>
              <tr>
                <th
                  colSpan={perms.order.length + 3}
                  className="bg-body-tertiary text-left text-800"
                >
                  {filteredVariantsOption[index]?.name}
                </th>
              </tr>
              <tr className="bg-body-tertiary text-center text-800">
                {perms.order.map((col, colIndex) => (
                  <th key={colIndex}>
                    {col.charAt(0).toUpperCase() + col.slice(1)}
                  </th>
                ))}
                <th>Price</th>
                <th>Special Price</th>
                <th>Stock</th>
              </tr>
            </thead>


            <tbody>
              {perms.permutations.map((perm, permIndex) => {
                const colorRows = perm.sizes?.length;
                const materialRows = perm.sizes?.length;

                return (perm?.sizes || []).map((size, sizeIndex) => (
                  <tr key={`${permIndex}-${sizeIndex}`}>
                    {perms.order.map((col, colIndex) => {
                      if (col === "colors") {
                        return sizeIndex === 0 ? (
                          <td
                            key={colIndex}
                            className="align-middle text-center"
                            rowSpan={colorRows}
                          >
                            {perm[col]}
                          </td>
                        ) : null;
                      } else if (col === "sizes") {
                        return (
                          <td
                            key={colIndex}
                            className="align-middle text-center p-1"
                          >
                            {size}
                          </td>
                        );
                      } else if (col === "materials") {
                        return sizeIndex === 0 ? (
                          <td
                            key={colIndex}
                            className="align-middle text-center"
                            rowSpan={materialRows}
                          >
                            {perm[col]}
                          </td>
                        ) : null;
                      } else {
                        return (
                          <td
                            key={colIndex}
                            className="align-middle text-center"
                          >
                            {perm[col] || "-"}
                          </td>
                        );
                      }
                    })}

                    <td className="text-center">
                      <InputGroup className="mb-3">
                        <Form.Control
                          type="number"
                          placeholder="Price"
                          title="Price"
                          value={rowData[permIndex]?.price || ""}
                          onChange={(e) =>
                            handleInputChange(index, "price", e.target.value)
                          }
                        />
                        <OverlayTrigger
                          overlay={
                            <Tooltip
                              style={{ position: "fixed" }}
                              id="overlay-trigger-example"
                            >
                              Apply to All
                            </Tooltip>
                          }
                        >
                          <Button variant="secondary">
                            <MdOutlineLibraryAddCheck />
                          </Button>
                        </OverlayTrigger>
                      </InputGroup>
                    </td>

                    <td className="text-center">
                      <InputGroup className="mb-3">
                        <Form.Control
                          type="number"
                          placeholder="Special Price"
                          title="Special Price"
                        />

                        <OverlayTrigger
                          overlay={
                            <Tooltip
                              style={{ position: "fixed" }}
                              id="overlay-trigger-example"
                            >
                              Apply to All
                            </Tooltip>
                          }
                        >
                          <Button variant="secondary">
                            <MdOutlineLibraryAddCheck />
                          </Button>
                        </OverlayTrigger>
                      </InputGroup>
                    </td>


                    <td className="text-center">
                      <InputGroup className="mb-3">
                        <Form.Control
                          type="number"
                          placeholder="Stock"
                          title="Stock"
                        />

                        <OverlayTrigger
                          overlay={
                            <Tooltip
                              style={{ position: "fixed" }}
                              id="overlay-trigger-example"
                            >
                              Apply to All
                            </Tooltip>
                          }
                        >
                          <Button variant="secondary">
                            <MdOutlineLibraryAddCheck />
                          </Button>
                        </OverlayTrigger>
                      </InputGroup>
                    </td>
                  </tr>
                ));
              })}
            </tbody>
          </React.Fragment>
        ))}
      </table> */}
      {variantsLoading || categoryVariantsLoading ? (
        <div className="d-flex py-3 justify-content-center align-items-center">
          <Button variant="falcon-default" size="sm" className="fs-10">
            Loading...
          </Button>
        </div>
      ) : (
        <table className="table table-bordered table-striped">
          {permutations?.map((perms, index) => (
            <React.Fragment key={index}>
              {/* Header for each variant */}
              <thead>
                <tr>
                  <th
                    colSpan={perms.order.length + 4}
                    className="bg-body-tertiary text-left text-800"
                  >
                    {filteredVariantsOption[index]?.name}
                  </th>
                </tr>
                <tr className="bg-body-tertiary text-center text-800">
                  {perms?.order.map((col, colIndex) => (
                    <th key={colIndex}>
                      {col.charAt(0).toUpperCase() + col.slice(1)}
                    </th>
                  ))}
                  <th>Price</th>
                  <th>Special Price</th>
                  <th>Stock</th>
                  <th>Sku</th>
                </tr>
              </thead>

              {/* Body for each permutation */}
              <tbody>
                {perms?.permutations?.flatMap((perm, permIndex) =>
                  perm?.sizes.map((size, sizeIndex) => {
                    const rowIndex = inputs.findIndex(
                      (row) =>
                        row.color === perm.colors &&
                        row.material === perm.materials &&
                        row.size === size
                    );
                    const row = rowIndex !== -1 ? inputs[rowIndex] : {};

                    return (
                      <tr key={`${permIndex}-${sizeIndex}`}>
                        {perms.order.map((col, colIndex) => {
                          if (col === "colors") {
                            return sizeIndex === 0 ? (
                              <td
                                key={colIndex}
                                className="align-middle text-center"
                                rowSpan={perm.sizes.length}
                              >
                                {perm[col]}
                              </td>
                            ) : null;
                          } else if (col === "sizes") {
                            return (
                              <td
                                key={colIndex}
                                className="align-middle text-center p-1"
                              >
                                {size}
                              </td>
                            );
                          } else if (col === "materials") {
                            return sizeIndex === 0 ? (
                              <td
                                key={colIndex}
                                className="align-middle text-center"
                                rowSpan={perm.sizes.length}
                              >
                                {perm[col]}
                              </td>
                            ) : null;
                          } else {
                            return (
                              <td
                                key={colIndex}
                                className="align-middle text-center"
                              >
                                {perm[col] || "-"}
                              </td>
                            );
                          }
                        })}
                        {/* Price */}
                        <td className="text-center py-1">
                          <InputGroup className="">
                            <Form.Control
                              type="number"
                              placeholder="Price"
                              title="Price"
                              value={row.price || ""}
                              onChange={(e) =>
                                handleInputChange(
                                  rowIndex,
                                  "price",
                                  e.target.value
                                )
                              }
                            />
                            <OverlayTrigger
                              overlay={
                                <Tooltip id="overlay-trigger-example">
                                  Apply to All
                                </Tooltip>
                              }
                            >
                              <Button
                                variant="secondary"
                                onClick={() =>
                                  handleApplyButtonClick(rowIndex, "price")
                                }
                              >
                                <MdOutlineLibraryAddCheck />
                              </Button>
                            </OverlayTrigger>
                          </InputGroup>
                        </td>
                        {/* Special Price */}
                        <td className="text-center py-1">
                          <InputGroup className="">
                            <Form.Control
                              type="number"
                              placeholder="Special Price"
                              title="Special Price"
                              value={row.specialPrice || ""}
                              onChange={(e) =>
                                handleInputChange(
                                  rowIndex,
                                  "specialPrice",
                                  e.target.value
                                )
                              }
                            />
                            <OverlayTrigger
                              overlay={
                                <Tooltip id="overlay-trigger-example">
                                  Apply to All
                                </Tooltip>
                              }
                            >
                              <Button
                                variant="secondary"
                                onClick={() =>
                                  handleApplyButtonClick(
                                    rowIndex,
                                    "specialPrice"
                                  )
                                }
                              >
                                <MdOutlineLibraryAddCheck />
                              </Button>
                            </OverlayTrigger>
                          </InputGroup>
                        </td>

                        {/* Stock */}
                        <td className="text-center py-1">
                          <InputGroup className="">
                            <Form.Control
                              type="number"
                              placeholder="Stock"
                              title="Stock"
                              value={row.stock || ""}
                              onChange={(e) =>
                                handleInputChange(
                                  rowIndex,
                                  "stock",
                                  e.target.value
                                )
                              }
                            />
                            <OverlayTrigger
                              overlay={
                                <Tooltip id="overlay-trigger-example">
                                  Apply to All
                                </Tooltip>
                              }
                            >
                              <Button
                                variant="secondary"
                                onClick={() =>
                                  handleApplyButtonClick(rowIndex, "stock")
                                }
                              >
                                <MdOutlineLibraryAddCheck />
                              </Button>
                            </OverlayTrigger>
                          </InputGroup>
                        </td>
                        <td className="text-center py-1">
                          <InputGroup className="">
                            <Form.Control
                              type="text"
                              placeholder="SKU"
                              title="SKU"
                              value={row.sku || ""}
                              onChange={(e) =>
                                handleInputChange(
                                  rowIndex,
                                  "sku",
                                  e.target.value
                                )
                              }
                            />
                            {/* <OverlayTrigger
                              overlay={
                                <Tooltip id="overlay-trigger-example">
                                  Apply to All
                                </Tooltip>
                              }
                            >
                              <Button
                                variant="secondary"
                                onClick={() =>
                                  handleApplyButtonClick(rowIndex, "stock")
                                }
                              >
                                <MdOutlineLibraryAddCheck />
                              </Button>
                            </OverlayTrigger> */}
                          </InputGroup>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </React.Fragment>
          ))}
        </table>
      )}
    </>
  );
};

function generatePermutations(data) {
  const allPermutations = [];

  // Validate the data structure
  if (!data || !Array.isArray(data) || data.length === 0) {
    return allPermutations;
  }

  // Process each variant separately
  data.forEach((item) => {
    const permutations = [];
    const variantOptions = {};
    const order = [];

    // Extract options in the order they appear in the input
    if (item.options && Array.isArray(item.options)) {
      item.options.forEach((optionGroup) => {
        const optionName = optionGroup.name.toLowerCase();
        variantOptions[optionName] = optionGroup.options || [];
        order.push(optionName); // Keep track of the order
      });
    }

    // Generate permutations dynamically based on extracted options
    const colors = variantOptions.colors || [];
    const sizes = variantOptions.sizes || [];
    const materials = variantOptions.materials || [];

    if (materials.length > 0) {
      // Generate permutations with materials
      colors.forEach((color) => {
        materials.forEach((material) => {
          const permutation = {};

          // Dynamically create permutation objects according to input order
          order.forEach((optionName) => {
            if (optionName === "colors") {
              permutation[optionName] = color;
            } else if (optionName === "sizes") {
              permutation[optionName] = sizes;
            } else if (optionName === "materials") {
              permutation[optionName] = material;
            }
          });

          permutations.push(permutation);
        });
      });
    } else {
      // Generate permutations without materials
      colors.forEach((color) => {
        const permutation = {};

        // Dynamically create permutation objects according to input order
        order.forEach((optionName) => {
          if (optionName === "colors") {
            permutation[optionName] = color;
          } else if (optionName === "sizes") {
            permutation[optionName] = sizes;
          }
        });

        permutations.push(permutation);
      });
    }

    // Add the permutations of the current variant to the result
    allPermutations.push({
      variant: item.name,
      permutations,
      order, // Include the order in the result
    });
  });

  return allPermutations;
}

export default PricingAndStockInfo;
